<template>
	<div>
		<v-toolbar flat color="primary" dark class="sticky-top" height="80">
			<v-toolbar-title class=" hidden-sm-and-down">
				{{ $t('SmartNavBar.Webshop') }}
			</v-toolbar-title>
			<!-- SEARCH -->
			<v-text-field v-model="searchValue" :placeholder="$t('ProductCatalog.Search_a_product')"
				@keyup.native.enter="applySearchFilter()" @click:append="applySearchFilter()"
				@click:clear="clearSearchFilter()" append-icon="mdi-magnify" flat hide-details clearable solo light
				dense style="width: 500px; max-width: 96vw" class="kmp">
			</v-text-field>
		</v-toolbar>

		<v-row no-gutters class="fill-height background">
			<v-col cols="auto">
				<!-- CATEGORIES -->
				<v-card flat class="transparent hidden-sm-and-down" :height="contentHeight - 88" max-width="350"
					style="position: sticky; top: 160px; overflow-y: auto;">
					<v-card-title>
						{{ $t('ProductCatalog.Product_catalog') }}
					</v-card-title>
					<v-treeview :items="categoryList" :item-key="categoryList.id" open-on-click activatable
						return-object dense :active.sync="currentCategory" @update:active="applyCategoryFilter()">
					</v-treeview>
				</v-card>
			</v-col>
			<v-col>
				<v-container>
					<!-- CURRENT CATEGORY -->
					<v-row v-show="currentCategoryName">
						<v-col>
							<div class="text-h6">
								{{ currentCategoryName }}
							</div>
						</v-col>
					</v-row>
					<!-- CATALOG ITEMS -->
					<v-row justify="center">
						<v-col cols="auto" v-for="(catalogItem, index) of itemList" :key="index">
							<CatalogItemCard :catalogItem="catalogItem" />
						</v-col>
					</v-row>
					<v-row justify="center">
						<!-- LOAD MORE -->
						<v-btn v-show="loadMore" class="my-3" :loading="loading" color="primary" @click="loadMoreItems">
							{{ $t('Load_more') }}
						</v-btn>
					</v-row>
				</v-container>
			</v-col>
			<v-col cols="1"></v-col>
		</v-row>
	</div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import CatalogItemCard from "../components/CatalogItemCard.vue";

export default {
	name: "ProductCatalog",

	components: {
		CatalogItemCard,
	},

	data() {
		return {
			searchValue: "",
			currentCategory: []
		};
	},

	created() {
		store.dispatch("catalog/fetchCatalog").then(() => {
			//this.mounted = true;
		});
	},

	computed: {
		...mapGetters({
			currentCategoryName: "catalog/currentCategoryName",
			contentHeight: "session/contentHeight",
			categoryList: "catalog/categoryList",
			itemList: "catalog/itemList",
			loading: "catalog/loading",
			rowNum: "catalog/rowNum",
			loadMore: "catalog/loadMore",
		}),
		marginLeft() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return '0pc'
				case 'sm': return '0pc'
				case 'md': return '0pc'
				case 'lg': return '30pc'
				case 'xl': return '25pc'
			}
		},
		marginRight() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return '0pc'
				case 'sm': return '0pc'
				case 'md': return '0pc'
				case 'lg': return '10pc'
				case 'xl': return '15pc'
			}
		},
		style() {
			const style = "margin-left: " + this.marginLeft + "; margin-right: " + this.marginRight + ";"
			return style
		}
	},

	methods: {
		applyCategoryFilter() {
			if (this.currentCategory[0]) {
				this.$store.commit("catalog/setCurrentCategory", this.currentCategory[0]);
				this.$store.dispatch("catalog/openQuery").then(() => {
					this.searchValue = ""
					this.$vuetify.goTo(0);
				});
			}
		},

		applySearchFilter() {
			this.$store.commit("catalog/setSearchText", this.searchValue)
			this.$store.dispatch("catalog/openQuery").then(() => {
				this.currentCategory = []
				this.$vuetify.goTo(0);
			});
		},

		clearSearchFilter() {
			this.searchValue = ""
			this.applySearchFilter()
		},

		loadMoreItems() {
			this.$store.dispatch("catalog/openQuery");
		},
	},
};
</script>
