<template>
	<v-card hover outlined width="200" v-on:click.stop="getCatalogItemDetail">
		<v-card-title>
			<v-img position="center center" :src="catalogItem.imageUrl ? catalogItem.imageUrl : '@/assets/imgnotfound.jpg'"
				width="125" height="125" aspect-ratio="1" contain>
				<v-sheet v-if="catalogItem.isNew" style="position: absolute; opacity: 0.8"
					class="info white--text text-caption ma-1 px-1" rounded>Nieuw
				</v-sheet>
			</v-img>
		</v-card-title>
		<v-card-subtitle>
			{{ catalogItem.itemName }}
		</v-card-subtitle>
	</v-card>
</template>
<script>
export default {
	name: "CatalogItemCard",
	props: {
		catalogItem: Object,
	},
	methods: {
		getCatalogItemDetail() {
			this.$router.push({
				name: "CatalogItem",
				params: { id: Number(this.catalogItem.itemId) },
			});
		},
	},
};
</script>
